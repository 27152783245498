<template>
  <base-layout :loadingFlag="$store.state.loading">
    <core-payment-breadcrumb />
    <div class="content">
      <user-info />
    </div>
    <account-header />
    <div class="columns">
      <div class="column is-one-quarter is-hidden-mobile">
        <sidebar />
      </div>
      <div class="column">
        <h1 class="title is-6">Payment Form</h1>

        <validation-observer v-slot="{ invalid }">
            
          <payment-form
            :showAmount="true"
            :lockAmount="amountLocked"
            :payInfo="payInfo"
            :showOnFile="false"
            @payment-info-updated="updatePaymentInfo"
          />
        
          <div class="field">
            <hr />
          </div>
          <core-payment-single-payment
              v-if="$route.query.action == 'oneTime' && hasSinglePaymentInfo && payInfo.amount != null && (payInfo.amount != singlePaymentInfo.pastDueAmt)"
              @single-payment-confirmed="submit('Y')"
              @single-payment-cancelled="cancel"
              :amount="payInfo.amount"
              :pastDueAmt="singlePaymentInfo.pastDueAmt"
              :nextSchedPmtAmt="singlePaymentInfo.nextSchedPmtAmt"
              :currPeriodOverpayment="singlePaymentInfo.currPeriodOverpayment"
              :totalPaymentDue="singlePaymentInfo.totalPaymentDue"
              :loading="$store.state.loading"
              :invalid="invalid"
          />
          <div class="buttons" v-else>
            <button
              class="button is-success"
              @click="submit('Y')"
              :disabled="invalid || $store.state.loading"
            >
              Continue
            </button>
            <router-link
              :to="{ name: 'makePayment', params: { id: $route.params.id } }"
              tag="button"
              class="button is-light"
              >Cancel</router-link
            >
          </div>
        </validation-observer>

        <payment-arrangements
          v-if="$route.query.action == 'next'"
          :id="$route.params.id"
        />
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': showPhonePayConf }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Pending Payments</p>
        </header>
        <section class="modal-card-body">
          There are payments pending on this account.  Do you want to submit an additional payment?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="closeShowPhonePayConf">Yes</button>
          <button class="button" @click="goBackToHome">No</button>
        </footer>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout.vue";
import AccountHeader from "@/components/AccountHeader.vue";
import PaymentArrangements from "@/components/PaymentArrangements.vue";
import PaymentForm from "@/components/payment/PaymentForm.vue";
import moment from "moment";
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapGetters } from "vuex";
import UserInfo from "@/components/UserInfo.vue";
import axios from "axios";
import Sidebar from "@/components/Sidebar.vue";
import Filters from "@/utils/filters";
import CorePaymentBreadcrumb from "@/components/CorePaymentBreadcrumb";
import CorePaymentSinglePayment from "@/components/CorePaymentSinglePayment";

function unloadListener(event) {
  // Cancel the event as stated by the standard.
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = "";
}

export default {
  components: {
    BaseLayout,
    AccountHeader,
    ValidationObserver,
    UserInfo,
    PaymentArrangements,
    Sidebar,
    PaymentForm,
    CorePaymentBreadcrumb,
    CorePaymentSinglePayment
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      billingAddress: null,
      city: null,
      state: null,
      zipCode: null,
      ccNumber: null,
      ccExpDateMonth: null,
      ccExpDateYear: null,
      cvv: null,
      paymentOption: null,
      routingNumber: null,
      bankAccountNumber: null,
      phone: null,
      amountLocked: false,
      paymentDate: null, // #2333 let server determine date
      payInfo: {
        paymentOption: null,
        amount: null,
        state: null
      },
      showPhonePayConf: false,
      checkNo: null,
      sendConf: false,
      confType: 'email',
      sms: null,
      email: null,
      paymentProviderEmail: null
    };
  },
  methods: {
    updatePaymentInfo(payload) {
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.billingAddress = payload.billingAddress;
      this.city = payload.city;
      this.state = payload.state;
      this.zipCode = payload.zipCode;
      this.ccNumber = payload.ccNumber;
      this.ccExpDateMonth = payload.ccExpDateMonth;
      this.ccExpDateYear = payload.ccExpDateYear;
      this.cvv = payload.cvv;
      this.paymentOption = payload.paymentOption;
      this.routingNumber = payload.routingNumber;
      this.bankAccountNumber = payload.bankAccountNumber;
      this.phone = payload.phone;
      this.payInfo.amount = payload.amount;
      this.checkNo = payload.checkNo;
      this.sendConf = payload.sendConf;
      this.confType = payload.confType;
      this.sms = payload.sms;
      this.email = payload.email;
      this.paymentProviderEmail = payload.paymentProviderEmail;
    },
    formatNum(num) {
      return String(num).replace(/\D/g, "");
    },
    async isValidRouting(value) {
      try {
        const response = await axios.get(
          process.env.VUE_APP_API_URL +
            "api/payments/bankExists;user=" +
            this.$store.state.username,
          {
            params: {
              routingNumber: value
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.jwt
            }
          }
        );
        return response.status == 200;
      } catch (e) {
        return false;
      }
    },
    cancel() {
      this.$router.push({ name: 'makePayment', params: { id: this.$route.params.id } });
    },
    async submit(buttonId) {
      let payload = {};

      if (this.paymentOption == "1" || this.paymentOption == "3") {
        let bankExists = await this.isValidRouting(this.routingNumber);
        if (!bankExists) {
          this.$store.commit("SET_GLOBAL_NOTIFICATION", {
            showNotification: true,
            notificationMessage: "Routing number does not exist",
            notificationType: "error"
          });
          return;
        }
      }

      let confirmEmail = null
      let confirmPhone = null
      let sendConfEmail = false
      let sendConfText = false

      if( this.sendConf ) {
        if( this.confType == 'email' && this.email ) {
          sendConfEmail = true
          confirmEmail = this.email
        } else if( this.confType == 'sms' && this.sms ) {
          sendConfText = true
          confirmPhone = this.sms
        }
      }

      switch (this.paymentOption) {
        case "0":
        case "2":
          payload = {
            instanceId: this.$route.params.instanceId,
            action: this.$route.query.action,
            refNum: this.$route.params.id,
            demIdtyNo: this.$store.getters["accounts/fullAccount"](
              this.$route.params.id
            ).DEMIDTYNO.value,
            firstName: this.firstName,
            lastName: this.lastName,
            address: this.billingAddress,
            city: this.city,
            state: this.state,
            zipCode: this.zipCode,
            paymentOption: this.paymentOption,
            cardNumber: this.ccNumber,
            expDateMonth: this.ccExpDateMonth,
            expDateYear: this.ccExpDateYear,
            ccv: this.cvv,
            feeAmount: 0,
            paymentDate: this.paymentDate,
            amount: this.payInfo.amount,
            needsSD: this.$route.query.needsSD,
            sdAccepted: this.$route.query.sdAccepted,
            achSECCode: 'WEB',
            confirmEmail: confirmEmail,
            confirmPhone: confirmPhone,
            sendConfEmail: sendConfEmail,
            sendConfText: sendConfText,
            email: this.email,
            paymentProviderEmail: this.paymentProviderEmail
          };
          break;
        case "1":
        case "3":
          payload = {
            instanceId: this.$route.params.instanceId,
            action: this.$route.query.action,
            refNum: this.$route.params.id,
            demIdtyNo: this.$store.getters["accounts/fullAccount"](
              this.$route.params.id
            ).DEMIDTYNO.value,
            firstName: this.firstName,
            lastName: this.lastName,
            address: this.billingAddress,
            city: this.city,
            state: this.state,
            zipCode: this.zipCode,
            paymentOption: this.paymentOption,
            routingNumber: this.routingNumber,
            accountNumber: this.bankAccountNumber,
            phone: this.phone,
            feeAmount: 0,
            paymentDate: this.paymentDate,
            amount: this.payInfo.amount,
            needsSD: this.$route.query.needsSD,
            sdAccepted: this.$route.query.sdAccepted,
            achSECCode: 'WEB',
            checkNo: this.checkNo,
            checkPos: "R", // eric default from FNCB
            confirmEmail: confirmEmail,
            confirmPhone: confirmPhone,
            sendConfEmail: sendConfEmail,
            sendConfText: sendConfText,
            email: this.email,
            paymentProviderEmail: this.paymentProviderEmail
          };
          break;
      }

      this.$store.commit("SET_PAYMENT_IN_PROGRESS", payload);

      if (await this.needsRegE()) {
        this.$router.push({
          name: "rege",
          params: {
            id: this.$route.params.id
          },
          query: {
            action: this.$route.query.action
          }
        });
      } else {
        this.$router.push({
          name: "confirm",
          params: {
            id: this.$route.params.id
          },
          query: {
            action: this.$route.query.action
          }
        });
      }
    },
    ...mapActions(["fetchPaymentPortalGlobalConfig","fetchPaymentPortalInstanceConfig","fetchUserInfo"]),
    ...mapActions("accounts", ["fetchAccounts"]),
    ...mapActions("pay", ["fetchPhonePays","fetchSinglePaymentInfo"]),
    async needsRegE() {
      let payload = {
        option: this.$store.state.paymentInProgress.paymentOption,
        action: this.$route.query.action,
        relatedAccount: this.$route.params.id
      };
      let regE = await this.$store.dispatch("fetchRegEMessages", payload);
      return regE != null && regE.length > 0;
    },
    closeShowPhonePayConf() {
      this.showPhonePayConf = false;
    },
    goBackToHome() {
      this.$router.push({ name: 'makePayment', params: { id: this.$route.params.id } });
    }
  },
  beforeRouteEnter(to, from, next) {

    next(async vm => {
      window.addEventListener("beforeunload", unloadListener);
      if (vm.$store.state.accounts.accounts.length == 0) {
        await vm.fetchAccounts();
      }
      if (
        Object.keys(vm.$store.state.globalConfig).length === 0 &&
        vm.$store.state.globalConfig.constructor === Object
      ) {
        await vm.fetchPaymentPortalGlobalConfig(vm.$route.params.instanceId);
      }
      if (
        Object.keys(vm.$store.state.instanceConfig).length === 0 &&
        vm.$store.state.instanceConfig.constructor === Object
      ) {
        await vm.fetchPaymentPortalInstanceConfig(vm.$route.params.instanceId);
      }
      if (
        vm.$store.state.userInfo == null ||
        vm.$store.state.userInfo.firstName == null
      ) {
        await vm.fetchUserInfo();
      }

      if (vm.$route.query.action == "full") {
        vm.payInfo.amount = vm.$store.getters["accounts/dueValue"](vm.$route.params.id);
        vm.amountLocked = true;
      } else {
        if (vm.$route.query.action == "next") {
          if( vm.$store.getters["accounts/fullAccount"](vm.$route.params.id)["Next Amt Due"] ) {  
            vm.payInfo.amount = Number
              .parseFloat(vm.$store.getters["accounts/fullAccount"](vm.$route.params.id)["Next Amt Due"].value)
              .toFixed(2);
          }
          if( vm.$store.getters["accounts/fullAccount"](vm.$route.params.id)["Next Due Date"] ) {
            vm.paymentDate = vm.$store.getters["accounts/fullAccount"](vm.$route.params.id)["Next Due Date"].value;
          }
          vm.amountLocked = true;
        } else if (vm.$route.query.action == "negotiator") {
            vm.payInfo.amount = vm.$store.state.negotiatorOfferAmount;
            vm.paymentDate = vm.$store.state.negotiatorOfferDate; // must be yyyy-MM-dd
            vm.amountLocked = true;
        } else { // user enters amount
            vm.payInfo.amount = null;
            vm.amountLocked = false;
        }
      }
      vm.$store.commit("SET_PAYMENT_IN_PROGRESS", null);

      //
      // #1767 warn consumers that they're going to add another phone pay
      //
      await vm.fetchPhonePays({
            instanceId: vm.$route.params.instanceId,
            dbtNo: vm.$route.params.id
          }
      );
      vm.showPhonePayConf = to.query.action != 'next' && vm.hasPhonePays;

      //
      // #1980 additional call to query PORTAL_SINGLE_PAYMENT
      //
      await vm.fetchSinglePaymentInfo({
            dbtNo: vm.$route.params.id
          }
      );
    });
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("beforeunload", unloadListener);
    next();
  },
  computed: {
    next10: () => {
      let a = [];
      let y = moment().year();
      for (let i = 0; i < 10; i++) {
        a.push(y + i);
      }
      return a;
    },
    ...mapState(["instanceConfig","userInfo"]),
    ...mapState("pay", ["singlePaymentInfo"]),
    ...mapGetters("pay", ["hasPhonePays","hasSinglePaymentInfo"]),
    sortedPaySelects() {
      if (this.userInfo.allowedPaySelects) {
        let newArray = [...this.userInfo.allowedPaySelects];
        return newArray.sort((a, b) => (a.value < b.value ? -1 : 1));
      } else {
        return [];
      }
    },
    dueAmount() {
      if( !this.$store.getters["accounts/fullAccount"](this.$route.params.id) ||
          !this.$store.getters["accounts/fullAccount"](this.$route.params.id)["Next Amt Due"] ) {
        return null;
      } else {
        return Number
            .parseFloat(this.$store.getters["accounts/fullAccount"](this.$route.params.id)["Next Amt Due"].value)
            .toFixed(2);
      }
    },
    dueDate() {
      if( !this.$store.getters["accounts/fullAccount"](this.$route.params.id) ||
          !this.$store.getters["accounts/fullAccount"](this.$route.params.id)["Next Due Date"] ) {
        return null;
      } else {
        return this.$store.getters["accounts/fullAccount"](this.$route.params.id)["Next Due Date"].value;
      }
    },
    message() {
      if( this.dueAmount ) {
        if((Number(this.payInfo.amount) < Number(this.dueAmount))) {
          const amt_s = Filters.formatCurrency(this.dueAmount);
          return `The entered amount is less than the due amount of ${amt_s}. Are you sure you want to proceed?`;
        } else {
          return "Would you like to use the new payment information to process this one-time payment to be applied to your next payment due?";
        }
      } else {
        return null;
      }
    }
  },
  filters: {
    formatMethod: value => {
      if (value == "CREDIT") {
        return "CREDIT CARD";
      } else if (value == "DEBIT") {
        return "DEBIT CARD";
      } else if (value == "CHECK") {
        return "BANK DRAFT - ACH";
      } else {
        return value;
      }
    }
  }
};
</script>
