var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.showNotification),expression:"$store.state.showNotification"}],staticClass:"notification is-radiusless",class:{
    'is-danger': _vm.$store.state.notificationType == 'error',
    'is-success': _vm.$store.state.notificationType == 'success',
    'is-info':
      _vm.$store.state.notificationType != 'error' &&
      _vm.$store.state.notificationType != 'success'
  }},[_c('button',{staticClass:"delete",on:{"click":_vm.clearNotification}}),_vm._v(" "+_vm._s(_vm.$store.state.notificationMessage)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }