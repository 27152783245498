const Filters = {
  formatCurrency(amount) {
    if (amount != null) {
      return Number(amount).toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      });
    } else {
      return null;
    }
  },
  formatDate(dt) {
    if (dt == null) {
      return null;
    }
    if (typeof dt === "string") {
      const regex1 = /([0-9]{4})-([0-9]{2})-([0-9]{2})/;
      const found = dt.match(regex1);
      if (found) {  // convert 2024-12-01 to 12/01/2024
        return `${found[2]}/${found[3]}/${found[1]}`;
      }
      return dt;
    } else {
      return dt.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        timeZone: "UTC"
      });
    }
  },
  formatPhone(phn) {
    if (phn != null && phn.length == 10) {
      return (
        "(" +
        phn.substring(0, 3) +
        ") " +
        phn.substring(3, 6) +
        "-" +
        phn.substring(6)
      );
    }
    return phn;
  },
  formatMethod: value => {
    if (value == "CREDIT") {
      return "CREDIT CARD";
    } else if (value == "DEBIT") {
      return "DEBIT CARD";
    } else if (value == "ACH") {
      return "CHECKING - ACH";
    } else if (value == "CHECK") {
      return "CHECKING";
    } else {
      return value;
    }
  }
};

export default Filters;
