const getters = {
  formattedName: state => {
    if (state.paymentInProgress == null) {
      return null;
    }
    return (
      state.paymentInProgress.firstName + " " + state.paymentInProgress.lastName
    );
  },
  maskedAccountNum: state => {
    if (state.paymentInProgress == null) {
      return null;
    }

    var num = null;
    if (state.paymentInProgress.paymentOption === "1") {
      num = state.paymentInProgress.accountNumber; // Check
    } else {
      num = state.paymentInProgress.cardNumber;
    }

    return num.substring(num.length - 4, num.length);
  },
  getFormParam: state => formField => {
    if (state.negotiatorSOD2FormFields) {
      return state.negotiatorSOD2FormFields[formField];
    }
    return null;
  },
  getRetVal: state => retVal => {
    if (state.negotiatorSOD2RetVals) {
      return state.negotiatorSOD2RetVals[retVal];
    }
    return null;
  },
  allowPmtArr: state => {
    if (state.arrangement !== undefined) {
      if (
        state.arrangement.ppa_status !== undefined &&
        state.arrangement.ppa_status == "B"
      ) {
        return false;
      }
      // #1649 need to add in check on state.arrangement.phr_post_date
    }
    return true;
  },
  settlementAcceptanceLabel: state => {
    if( state.instanceConfig && state.instanceConfig.settlementAcceptanceLabel ) {
      return state.instanceConfig.settlementAcceptanceLabel;
    } else {
      return "Settlement Acceptance";
    }
  },
  amortScheduledLabel: state => {
    if( state.instanceConfig && state.instanceConfig.amortScheduledLabel ) {
      return state.instanceConfig.amortScheduledLabel;
    } else {
      return "Scheduled";
    }
  },
  amortPrincipalLabel: state => {
    if( state.instanceConfig && state.instanceConfig.amortPrincipalLabel ) {
      return state.instanceConfig.amortPrincipalLabel;
    } else {
      return "Principal";
    }
  },
  amortInterestLabel: state => {
    if( state.instanceConfig && state.instanceConfig.amortInterestLabel ) {
      return state.instanceConfig.amortInterestLabel;
    } else {
      return "Interest";
    }
  },
  amortPenaltyLabel: state => {
    if( state.instanceConfig && state.instanceConfig.amortPenaltyLabel ) {
      return state.instanceConfig.amortPenaltyLabel;
    } else {
      return "Penalty";
    }
  },
  amortAmountLabel: state => {
    if (state.instanceConfig && state.instanceConfig.amortAmountLabel) {
      return state.instanceConfig.amortAmountLabel;
    } else {
      return "Amount";
    }
  },
  amortBalanceLabel: state => {
    if (state.instanceConfig && state.instanceConfig.amortBalanceLabel) {
      return state.instanceConfig.amortBalanceLabel;
    } else {
      return "Balance";
    }
  }
};

export default getters;
